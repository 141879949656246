exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-business-contract-js": () => import("./../../../src/pages/business-contract.js" /* webpackChunkName: "component---src-pages-business-contract-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ip-contract-js": () => import("./../../../src/pages/ip-contract.js" /* webpackChunkName: "component---src-pages-ip-contract-js" */),
  "component---src-pages-legal-entity-contract-js": () => import("./../../../src/pages/legal-entity-contract.js" /* webpackChunkName: "component---src-pages-legal-entity-contract-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-self-employed-contract-js": () => import("./../../../src/pages/self-employed-contract.js" /* webpackChunkName: "component---src-pages-self-employed-contract-js" */),
  "component---src-pages-services-cash-on-delivery-js": () => import("./../../../src/pages/services/cash-on-delivery.js" /* webpackChunkName: "component---src-pages-services-cash-on-delivery-js" */),
  "component---src-pages-services-courier-js": () => import("./../../../src/pages/services/courier.js" /* webpackChunkName: "component---src-pages-services-courier-js" */),
  "component---src-pages-services-delivery-eaeu-js": () => import("./../../../src/pages/services/delivery-eaeu.js" /* webpackChunkName: "component---src-pages-services-delivery-eaeu-js" */),
  "component---src-pages-services-discounts-js": () => import("./../../../src/pages/services/discounts.js" /* webpackChunkName: "component---src-pages-services-discounts-js" */),
  "component---src-pages-services-fitting-clothes-js": () => import("./../../../src/pages/services/fitting-clothes.js" /* webpackChunkName: "component---src-pages-services-fitting-clothes-js" */),
  "component---src-pages-services-fulfilment-js": () => import("./../../../src/pages/services/fulfilment.js" /* webpackChunkName: "component---src-pages-services-fulfilment-js" */),
  "component---src-pages-services-inspection-js": () => import("./../../../src/pages/services/inspection.js" /* webpackChunkName: "component---src-pages-services-inspection-js" */),
  "component---src-pages-services-marketplace-js": () => import("./../../../src/pages/services/marketplace.js" /* webpackChunkName: "component---src-pages-services-marketplace-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

